require("./bootstrap")
import "./animaciones"

export const notificarCorrecto = (message) => {
    toastr["success"](message, "Correcto", {
        closeButton: true,
        positionClass: "toast-bottom-right",
    })
}

export const notificarError = (message) => {
    toastr["error"](message, "Error", {
        closeButton: true,
        positionClass: "toast-bottom-right",
    })
}

export const notificarAdvertencia = (message) => {
    toastr["warning"](message, "Advertencia", {
        closeButton: true,
        positionClass: "toast-bottom-right",
    })
}

window.notificaciones = {
    notificarCorrecto,
    notificarError,
    notificarAdvertencia,
}
